import React, { useEffect, useState } from 'react';
import './App.css';
import Button from '@mui/material/Button';
import SvgConverter from './SvgConverter';
import Trademark from './Trademark';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const App = () => {
  return (
    <Router>
      {/* Rota Tanımları */}
      <Routes>
        <Route path="/" element={<Home />} /> {/* Ana sayfa */}
        <Route path="/converter" element={<SvgConverter />} /> {SvgConverter}
        <Route path="/trademark" element={<Trademark />} /> {Trademark}
      
      </Routes>
    </Router>
  );
};


const CustomButton = styled(Button)({
  borderColor: 'black',
  color: 'black',
  border: '2px solid #1A1A1A',
  borderRadius: '15px',
  boxSizing: 'border-box',
  fontFamily: 'Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  fontSize: '16px',
  fontWeight: 600,
  minHeight: '60px',
  padding: '16px 24px',
  transition: 'all 300ms cubic-bezier(.23, 1, 0.32, 1)',
  width: '100%',
  marginBottom: '6%',
  '&:hover': {
    color: '#fff',
    backgroundColor: '#1A1A1A',
    borderColor: 'rgba(121, 147, 81)',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0 8px 15px',
    transform: 'translateY(-2px)',
  },
  '&:active': {
    boxShadow: 'none',
    transform: 'translateY(0)',
  },
  '&:disabled': {
    pointerEvents: 'none',
  },
});
const Home = () => {
  const imageList = [
    '/Images/Shops/Nile/DesignCluster1/Design4/Design4.svg',
    '/Images/Shops/Nile/DesignCluster1/Design5/Design5.svg',
    '/Images/Shops/Pretty/DesignCluster1/Design1/Design1.svg',
    '/Images/Shops/Nile/DesignCluster1/Design11/i.svg',
    '/Images/Shops/Pretty/DesignCluster1/Design7/Design7.svg',
    '/Images/Shops/Pretty/DesignCluster1/Design10/e.svg',
    '/Images/Shops/Nile/DesignCluster1/Design9/w.svg',
  ];

  // Şu anki resim indeksini tutan state
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true); // Görünürlük state

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => 
          (prevIndex + 1) % imageList.length 
        );
        setIsVisible(true); 
      }, 1000); 
      
    }, 4000); // 4 saniye

    // Temizlik işlevi, bileşen kapandığında interval'i temizler
    return () => clearInterval(intervalId);
  }, [imageList.length]);

  return (
    <div className='OuterContainer'>
      <div className='MenuContainer'>
        <img 
          src='/Logo.png' 
          alt='Logo' 
          className='logoImage' 
        />
        <MenuComponent />
      </div>
      <div className="Divider"></div>
      <div className='LogoContainer'>
        <img 
          src={imageList[currentImageIndex]} 
          alt='Logo' 
          className={`LoopImage ${isVisible ? 'visible' : 'hidden'}`} 
        />
      </div>
    </div>
  );
};

const MenuComponent = () => {
  const navigate = useNavigate(); // useNavigate hook'u ile yönlendirme yapılır.

  const handleNavigation = (id) => {
    if (id === "Converter") {
      navigate('/converter');
    } else if (id === "Trademark") {
      navigate('/trademark');
    }
  };
  
  return (
    <>
      <CustomButton id={"converter"} onClick={() => handleNavigation("Converter")}>
        Dizayn Dönüştür
      </CustomButton>
      <CustomButton id={"admin"}>
        Dizayn Tanımla
      </CustomButton>
      <CustomButton id={"trademark"} onClick={() => handleNavigation("Trademark")}>
        Trademark
      </CustomButton>
    </>
  );
};

export default App;
