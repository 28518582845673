import React, { useEffect, useState } from 'react';
import './Trademark.css';
import Button from '@mui/material/Button';
import SvgConverter from './SvgConverter';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import {  CircularProgress, Backdrop, TextField, Checkbox } from '@mui/material';
import axios from 'axios';
import { parse } from 'jsonc-parser';
import { Visibility } from '@mui/icons-material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import * as XLSX from 'xlsx';
import { Alert, AlertTitle } from '@mui/material';
const Trademark = () => {

    const [rows, setRows] = useState([]);
    const [newWordmark, setNewWordmark] = useState('');
    const [newSerialNumber, setNewSerialNumber] = useState('');
    const [file, setFile] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Şifre kontrol durumu
    const [password, setPassword] = useState('');
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [customClass, setCustomClass] = useState('');
    const [txtClass, setTxtClass] = useState('');
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(''); 
    const [errorMessage, setErrorMessage] = useState('');  
    const correctPassword = '1234'; // Şifreyi buradan belirleyebilirsin
    let status = '1';
    let path  = status === '0' ?  'http://localhost:3003' : 'https://outlettee.com:3003';
    const handlePasswordSubmit = () => {
        if (password === correctPassword) {
        setIsAuthenticated(true); // Şifre doğruysa sayfa gösterilecek
        } else {
        alert('Wrong password');
        }
    };

    const handleCheckboxChange = (event) => {
      const { value, checked } = event.target;
  
      if (value === 'Hepsi') {
        setSelectedClasses([]); // Reset everything if 'Hepsi' is selected
        setTxtClass(''); // Clear txtClass when 'Hepsi' is selected
      } else {
        // Update selected classes based on checkboxes
        let updatedClasses = checked
          ? [...selectedClasses, value]
          : selectedClasses.filter((item) => item !== value);
  
        setSelectedClasses(updatedClasses);
  
        // Update txtClass with comma-separated values
        const finalTxtClass = updatedClasses.join(',');
  
        // Include custom class if it exists
        setTxtClass(customClass ? `${finalTxtClass},${customClass}` : finalTxtClass);
      }
    };
  
    const handleCustomClassChange = (event) => {
      const value = event.target.value;
      setCustomClass(value);
  
      // Update txtClass by appending the custom class to selected checkboxes
      const finalTxtClass = selectedClasses.join(',');
      setTxtClass(value ? `${finalTxtClass},${value}` : finalTxtClass);
    };

    useEffect(() => {
        const fetchShopData = async () => {
            try {
            
                const response = await axios.get(path+'/api/trademarks'); 
                const jsonData = response.data; // API'den gelen verileri alıyoruz
                
                // Gelen veriyi tabloya uygun formatta düzenleyin
                const formattedData = jsonData.map((item) => ({
                    id: item.id,
                    Wordmark: item.Wordmark,
                    SerialNumber: item.SerialNumber,
                    active: item.active ?? true // Varsayılan olarak "active" true
                }));
                
                setRows(formattedData); // Rows state'ini güncelliyoruz
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchShopData(); // API verisini çek
    }, []); // useEffect sadece bir kere çağrılır
  
    const columns = [
      { field: 'id', headerName: 'ID', width: 90 },
      { field: 'Wordmark', headerName: 'Wordmark', width: 150, editable: true },
      { field: 'SerialNumber', headerName: 'Serial Number', width: 150, editable: true },
      {
        field: 'active',
        headerName: 'Active',
        width: 100,
        renderCell: (params) => (
          <Checkbox
            checked={params.row.active}
            onChange={() => toggleActive(params.row.id)}
          />
        )
      },
    ];
    const responseColumns = [
    { field: 'TITLE', headerName: 'Title', width: 200 },
    { field: 'TAGS', headerName: 'TAGS', width: 130 },
    { field: 'SKU', headerName: 'SKU', width: 130 },
    { field: 'EşleşenKelime', headerName: 'Eşleşen Kelime', width: 150 },
    { field: 'TrademarkSerialNumber', headerName: 'Trademark Serial Number', width: 200 },
    { field: 'EşleşmeYeri', headerName: 'Eşleşme Yeri', width: 200 },
  ];
    const addNewRow = async () => {
        const newId = rows.length + 1;
        const newRow = {
          id: newId,
          Wordmark: newWordmark,
          SerialNumber: newSerialNumber,
          active: true,
        };
        try {
          const response = await axios.post(path+'/api/trademarks', newRow); // API'ye POST isteği
          setRows([...rows, response.data]); // Yeni veriyi state'e ekle
        } catch (error) {
          console.error('Error adding new data:', error);
        }
      };
    
      // Seçilen satırı silme fonksiyonu (backend'e DELETE isteği gönderiyoruz)
      const deleteRows = async (selectionModel) => {
        try {
          await axios.delete(path+`/api/trademarks/${selectionModel[0]}`); // İlk seçilen ID'yi sil
          setRows(rows.filter(row => !selectionModel.includes(row.id))); // State'i güncelle
        } catch (error) {
          console.error('Error deleting data:', error);
        }
      };
    
      // Aktif/pasif durumunu değiştirme fonksiyonu (backend'e PUT isteği ile güncelliyoruz)
      const toggleActive = async (id) => {
        const updatedRows = rows.map(row =>
          row.id === id ? { ...row, active: !row.active } : row
        );
        const updatedRow = updatedRows.find(row => row.id === id);
    
        try {
          await axios.put(path+`/api/trademarks/${id}`, updatedRow); // Güncellenen veriyi API'ye gönder
          setRows(updatedRows); // State'i güncelle
        } catch (error) {
          console.error('Error updating data:', error);
        }
      };
    
      const processRowUpdate = async (newRow, oldRow) => {
        try {
          // Güncellenen satırı backend'e gönder
          const response = await axios.put(path+`/api/trademarks/${newRow.id}`, newRow);
          return response.data; // API'den dönen güncellenmiş veriyi geri döndürüyoruz
        } catch (error) {
          console.error('Error updating data:', error);
          return oldRow; // Hata durumunda eski veriyi geri döndürüyoruz
        }
      };
      
      const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
      if (!file) {
        alert('Lütfen bir dosya seçin!');
        return;
      }
  
      setLoading(true);
      setSuccessMessage(''); 
      setErrorMessage('');  

      const formData = new FormData();
      formData.append('file', file);
      formData.append('txtClass', txtClass);
  
      try {
        const response = await axios.post(path+'/api/upload-excel', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        
        setTableData(response.data.data);
        setSuccessMessage(response.data.message); 
      
      } catch (error) {
        setErrorMessage('Trademark karşılaştırma sırasında bir hata oluştu! ' + error);
      } finally {
        setLoading(false);
      }
    };

    if (!isAuthenticated) {
        return (
          <div className="password-screen">
            <div className="password-popup">
              <h3>Enter Password</h3>
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button onClick={handlePasswordSubmit} variant="contained" style={{ marginTop: 10 }}>
                Submit
              </Button>
            </div>
          </div>
        );
      }
      const ExportToExcel = () => {
        // Map tableData to an array of objects with keys matching column headers
        const dataToExport = tableData.map(row => {
          return {
            ID: row.id,
            Title: row.TITLE,
            TAGS: row.TAGS,
            SKU: row.SKU,
            'Eşleşen Kelime': row.EşleşenKelime,
            'Trademark Serial Number': row.TrademarkSerialNumber,
            'Eşleşme Yeri': row.EşleşmeYeri,
          };
        });
      
        // Create a new worksheet
        const ws = XLSX.utils.json_to_sheet(dataToExport);
      
        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Results");
      
        // Export the workbook to an Excel file
        XLSX.writeFile(wb, "tableData.xlsx");
      };
      return (
        <div className={`TM-outer-container ${isAuthenticated ? 'visible' : ''}`}>
          <div className="TM-container">
        
            <div className="TM-layout-panel" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                <div>
                    <h2>Trademarka Dahil Edilmeyecek Değerler</h2>
                </div>
                
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',  marginTop: 20, marginBottom:10 }}>
                <TextField
                  label="Wordmark"
                  value={newWordmark}
                  onChange={(e) => setNewWordmark(e.target.value)}
                />
                <TextField
                  label="Serial Number"
                  value={newSerialNumber}
                  onChange={(e) => setNewSerialNumber(e.target.value)}
                />
               
              </div>
              <div> <Button onClick={addNewRow} variant="contained" style={{ marginLeft: 10, marginBottom:10  }}>
                  Add New
                </Button>
              </div>
              <Box sx={{ height: '60%', width: '76%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  checkboxSelection
                  processRowUpdate={processRowUpdate} 
                  onRowSelectionModelChange={(newSelection) => {
                    deleteRows(newSelection);
                  }}
                  disableRowSelectionOnClick
                />
              </Box>
    
            </div>
            <div className="TM-left-panel">
                 <div>
                    <h2>Trademark Arama Ekranı</h2>
                </div>
                <div>
                     <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox value="Hepsi" onChange={handleCheckboxChange} />}
                        label="Hepsi"
                      />
                      <FormControlLabel
                        control={<Checkbox value="14" onChange={handleCheckboxChange} />}
                        label="14"
                      />
                      <FormControlLabel
                        control={<Checkbox value="18" onChange={handleCheckboxChange} />}
                        label="18"
                      />
                      <FormControlLabel
                        control={<Checkbox value="25" onChange={handleCheckboxChange} />}
                        label="25"
                      />
                      <FormControlLabel
                        control={<Checkbox value="35" onChange={handleCheckboxChange} />}
                        label="35"
                      />
                      <TextField
                        label="Custom Class"
                        variant="outlined"
                        size="small"
                        style={{ marginLeft: 10 }}
                        value={customClass}
                        onChange={handleCustomClassChange}
                      />
                    </FormGroup>
                </div>
                <div style={{ marginTop: 30 }}>
                    <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
                    <Button variant="contained" onClick={handleUpload} style={{ marginLeft: 10 }}>
                        Arama Yap
                    </Button>
                    <Button variant="contained" onClick={ExportToExcel} style={{ marginLeft: 10 }}>
                       Excele Çıkart
                    </Button>
                    <div style={{ marginTop: 30 }}>
                    {successMessage && (
                      <Alert severity="success" onClose={() => setSuccessMessage('')}>
                        <AlertTitle>Başarılı</AlertTitle>
                        {successMessage}
                      </Alert>
                    )}

                    {/* Hata mesajı */}
                    {errorMessage && (
                      <Alert severity="error" onClose={() => setErrorMessage('')}>
                        <AlertTitle>Hata</AlertTitle>
                        {errorMessage}
                      </Alert>
                    )}

                    </div>
                 
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
                  <div style={{ height: 400, width: '90%',marginTop: 20 }}>
                    <DataGrid
                      rows={tableData}
                      columns={responseColumns}
                      pageSize={5}
                      rowsPerPageOptions={[5, 10, 20]}
                     
                      disableSelectionOnClick
                    />

                </div>
            </div>
          </div>
        </div>
      );
    };
    
export default Trademark;
